import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enJson from './resource/en.json'
import jaJson from './resource/ja.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enJson
      },
      ja: {
        translation: jaJson
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
