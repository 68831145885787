import { useState } from "react";
import {
  Button,
  RedButton,
  GrayButton,
} from "../../component/form/Button";
import {
  TextInput,
} from "../../component/form/Input";
import { SideBar } from "../../component/layout/SideBar";
import { Title } from "../../component/layout/Title";

function Page() {

  const [ text, setText ] = useState('')

  const doClick = () => {
    alert('X')
  }

  return (
    <div className="frame">
      <SideBar active="component-sample" />
      <div className="main">
        <Title>
          Component Sample
        </Title>
        <div className="contents">
          <div className="heading-1">Text Styles</div>
          <div>
            <div className="heading-1">Heading 1</div>
            <div className="heading-2">Heading 2</div>
            <div className="heading-3">Heading 3</div>
            <div className="heading-4">Heading 4</div>
            <div className="heading-5">Heading 5</div>
            <div className="heading-6">Heading 6</div>
          </div>
          <div>
            <div className="b-body-title">B Body Title</div>
            <div className="l-body-title">L Body Title</div>
            <div className="b-body-text-large">B Body Text Large</div>
            <div className="l-body-text-large">L Body Text Large</div>
            <div className="b-body-text">B Body Text</div>
            <div className="l-body-text">L Body Text</div>
            <div className="b-body-text-small">B Body Text Small</div>
            <div className="l-body-text-small">L Body Text Small</div>
            <div className="b-small-text">B Small Text</div>
            <div className="l-small-text">L Small Text</div>
            <div className="b-caption-text">B Caption Text</div>
            <div className="l-caption-text">L Caption Text</div>
            <div className="b-caption-text-2">B Caption Text 2</div>
            <div className="l-caption-text-2">L Caption Text 2</div>
          </div>
          <div className="heading-1">Buttons</div>
          <div>
            <Button onClick={ doClick }>ボタン</Button>
            <RedButton onClick={ doClick }>ボタン</RedButton>
            <GrayButton onClick={ doClick }>ボタン</GrayButton>
          </div>
          <div className="heading-1">Input Field</div>
          <div>
            <TextInput
              label="Title"
              value={ text }
              onChange={ setText }
              caption="Captions"
              placeholder="Input Text"
            />
            <TextInput
              label="Title"
              value={ text }
              onChange={ setText }
              placeholder="Input Text"
              errorCaption="Please input your name."
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page;
