import React, { ChangeEvent } from 'react';
import classnames from 'classnames';
import styles from './Input.module.scss';

/**
 * テキスト入力欄のパラメータ。
 */
export type InputProps = {
  /** 入力欄のラベル */
  label: string,
  /** 入力値 */
  value: string,
  /** 入力値変更イベント */
  onChange: (newValue: string) => void,
  /** メッセージ */
  caption: string,
  /** エラーメッセージ */
  errorCaption?: string,
  /** 編集不可の場合trueにする */
  disabled?: boolean,
  /** Style class name */
  className?: string,
  /** 入力欄の入力サンプル */
  placeholder?: string,
  /** 入力欄のタイプ */
  type?: 'text' | 'date' | 'number' | 'password',
}
export function TextInput({
  label,
  value,
  onChange,
  caption,
  errorCaption,
  disabled,
  className,
  placeholder,
  type,
}: InputProps): React.ReactNode {
  const doChange = (element: ChangeEvent<HTMLInputElement>) => {
    onChange(element.target.value)
  }
  return (
    <div className={ classnames(styles.frame, className, !errorCaption ? '' : styles.error) }>
      <div className={ classnames("b-body-text", styles.title) }>
        { label }
      </div>
      <div>
        <input
          type={ type || 'text' }
          value={ value }
          onChange={ doChange }
          className={ classnames("l-body-text", styles.input) }
          placeholder={ placeholder || '' }
        />
      </div>
      <div className={ classnames("l-caption-text-2", styles.caption) }>
        { errorCaption || caption }
      </div>
    </div>
  )
}
