import React from "react"

interface Props {
  children: React.ReactNode,
}

export function Title({ children }: Props) {
  return (
    <>
      <div className="page-title">
        { children }
      </div>
      <div className="bar"></div>
    </>
  )
}