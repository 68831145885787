import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import styles from './SideBar.module.scss'
import * as Svg from '../Svg'
import { isDevelopment } from '../../util/Common'

type Props = {
  /** アクティブなメニューのメニュー名 */
  active: 'my-work' |
    'my-classroom' |
    'world-classroom' |
    'world-classroom-admin' |
    'my-performance' |
    'component-sample',
}

/**
 * SideMenu Component.
 * @param props.
 * @returns SideMenu Component.
 */
export const SideBar = ({ active }: Props) => {

  const { i18n } = useTranslation()
  const [ lang, setLang ] = useState(i18n.resolvedLanguage)
  // const { name, isTeacher } = {} /// useAppContext()
  const isTeacher = true;
  const name = 'Test';
  const navigate = useNavigate()

  // 言語切替
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [ i18n, lang ])

  return (
    <div className={styles.frame}>
      <div className={styles.logo}><img src="/image/WorldClassroom-03.png" width={152} height={116} alt="world-classroom logo" /></div>
      <MenuItem
        icon={<Svg.MyWork active={ active === 'my-work' } />}
        text="My Work"
        active={ active === 'my-work' }
        onClick={ () => navigate('/my-work/') }
      />
      <MenuItem
        icon={<Svg.MyClassroom active={ active === 'my-classroom' } />}
        text="My Classroom"
        active={ active === 'my-classroom' }
        onClick={ () => navigate('/my-classroom/') }
      />
      <MenuItem
        icon={<Svg.WorldClassroom active={ active === 'world-classroom' } />}
        text="World Classroom"
        active={ active === 'world-classroom' }
        onClick={ () => navigate('/world-classroom/') }
      />
      {isTeacher && (
        <MenuItem
          icon={<Svg.WorldClassroom active={ active === 'world-classroom-admin'}/>}
          text="World Classroom Admin"
          active={ active === 'world-classroom-admin' }
          onClick={ () => navigate('/world-classroom-admin/') }
        />
      )}
      <MenuItem
        icon={<Svg.MyPerformance active={ active === 'my-performance' } />}
        text="My Performance"
        active={ active === 'my-performance' }
        onClick={ () => navigate('/my-performance/') }
      />
      {isDevelopment && (
        <MenuItem
          icon={<Svg.Warning active={ active === 'component-sample' } />}
          text="Component Sample"
          active={ active === 'component-sample' }
          onClick={ () => navigate('/component-sample/') }
        />
      )}
      <div className={ styles['i18n-area'] }>
        {lang === 'ja' ? (
          <button type="button" onClick={() => setLang('en')}>English mode</button>
        ) : (
          <button type="button" onClick={() => setLang('ja')}>日本語版</button>
        )}
      </div>
      <div className={styles['user-info']}>
        <Svg.AvatarDummy />
        <div>
          { name }
        </div>
      </div>
    </div>
  )
}

/**
 * SideMenu Thin Component.
 * @param _ なし.
 * @returns SideMenu Thin Component.
 */
export const Small: React.FC = _ => {
  const doRefresh = () => {
    window.location.reload()
  }
  return (
    <div className={styles['frame-small']}>
      <div style={{marginTop: 32, marginBottom: 72}} onClick={doRefresh}><Svg.Brand /></div>
      {/* <MenuItemSmall icon={<Svg.MyWork />} />
      <MenuItemSmall icon={<Svg.MyClassroom />} />
      <MenuItemSmall icon={<Svg.WorldClassroom />} active={true} />
      <MenuItemSmall icon={<Svg.MyPerformance />} /> */}
      <MenuItemSmall icon={<Svg.Refresh />} onClick={doRefresh}/>
    </div>
  )
}

type MenuItemProps = {
  /** メニューのアイコン. */
  icon: JSX.Element,
  /** メニューのテキスト */
  text: string,
  /** アクティブなメニューの時trueを設定する. */
  active?: boolean,
  /** メニューをクリックされた場合のCallback. */
  onClick?: Function,
}

const MenuItem = ({ icon, text, active, onClick }: MenuItemProps) => {

  const onClickDiv = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      className={classnames(
        styles['menu-item'],
        active && styles['menu-item-active']
      )}
      onClick={onClickDiv}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{text}</div>
    </div>
  )
}

type MenuItemSmallProps = {
  /** メニューのアイコン. */
  icon: JSX.Element,
  /** アクティブなメニューの時trueを設定する. */
  active?: boolean,
  /** メニューをクリックされた場合のCallback. */
  onClick?: Function,
}

const MenuItemSmall = ({ icon, active, onClick }: MenuItemSmallProps) => {
  const onClickDiv = () => {
    if (onClick) {
      onClick()
    }
  }
  return (
    <div
      className={classnames(
        styles.icon,
        active && styles['icon-active']
      )}
      onClick={onClickDiv}
    >
      {icon}
    </div>
  )
}
