import "./i18n"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./page/common/Login";
import MyWork from "./page/my-work/Index";
import MyClassroom from "./page/my-classroom/Index";
import WorldClassroom from "./page/world-classroom/Index";
import WorldClassroomAdmin from "./page/world-classroom-admin/Index";
import MyPerformance from "./page/my-performance/Index";
import ComponentSample from "./page/component-sample/Index";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/my-work/" element={ <MyWork /> } />
          <Route path="/my-classroom/" element={ <MyClassroom /> } />
          <Route path="/world-classroom/" element={ <WorldClassroom /> } />
          <Route path="/world-classroom-admin/" element={ <WorldClassroomAdmin /> } />
          <Route path="/my-performance/" element={ <MyPerformance /> } />
          <Route path="/component-sample/" element={ <ComponentSample /> } />
          <Route path="/*" element={ <Login /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
