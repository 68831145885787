import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';

type Props = {
  /** クリックイベントハンドラー. */
  onClick: () => void,
  /** ボタンに追加で設定する CSS class name. */
  className?: string,
  /** タグの中身. */
  children?: React.ReactNode,
  /** 押せない時trueにする. */
  disabled?: boolean,
};

/**
 * 標準ボタン（水色）コンポーネント
 *
 * @param props パラメータ
 * @returns Button Component
 */
export function Button({
  onClick,
  disabled,
  className,
  children
}: Props) {
  return (
    <button
      type="button"
      onClick={ onClick }
      disabled={ disabled }
      className={classnames(styles.button, className)}
    >
      { children }
    </button>
  );
}

/**
 * 赤色ボタンコンポーネント
 *
 * @param props パラメータ
 * @returns Button Component
 */
export function RedButton(props: Props) {
  return (
    <Button
      { ...props }
      className={classnames(styles.red, props.className)}
    >
      { props.children }
    </Button>
  );
}

/**
 * 灰色ボタンコンポーネント
 *
 * @param props パラメータ
 * @returns Button Component
 */
export function GrayButton (props: Props) {
  return (
    <Button
      { ...props }
      className={classnames(styles.gray, props.className)}
    >
      { props.children }
    </Button>
  );
}
