import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import {
  TextInput
} from "../../component/form/Input";
import {
  Button
} from "../../component/form/Button";

export default function Login() {

  const navigate = useNavigate();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ isClick, setIsClick ] = useState(false);

  const doLogin = () => {
    setIsClick(true)
    if (!email || !password) {
      return;
    }
    navigate('/my-work/');
  };

  return (
    <div className={ styles.frame }>
      <div className="heading-1">Login</div>
      <TextInput
        label="E-mail"
        value={ email }
        onChange={ setEmail }
        placeholder="hello@hello-world.city"
        caption="please input email address"
        errorCaption={ isClick && !email ? 'please input email address' : '' }
      />
      <TextInput
        type="password"
        label="password"
        value={ password }
        placeholder="********"
        onChange={ setPassword }
        caption="please input login password"
        errorCaption={ isClick && !password ? 'please input login password' : '' }
      />
      <Button
        onClick={ doLogin }
      >
        Login
      </Button>
    </div>
  )
}
