import { SideBar } from "../../component/layout/SideBar";
import { Title } from "../../component/layout/Title";

function Page() {
  return (
    <div className="frame">
      <SideBar active="world-classroom" />
      <div className="main">
        <Title>
          World Classroom
        </Title>
        <div className="contents">
        </div>
      </div>
    </div>
  );
}
export default Page
